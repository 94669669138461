import Image from 'next/image';
import { Play } from 'lucide-react';
import React from 'react';
import { extractBoldText } from '@/utils/htmlParser';

interface BannerItemProps {
  image?: string;
  videoUrl?: string;
  alt: string;
  title: string;
  description: string;
  isNewsLayout?: boolean;
}

const BannerItem = ({
  image,
  videoUrl,
  alt,
  title,
  description,
  isNewsLayout,
}: BannerItemProps) => {
  const { bold, original } = extractBoldText(title);

  if (isNewsLayout) {
    return (
      <div className="w-full md:w-[1920px] h-[550px] px-4 md:px-[153px] py-20 bg-black flex-col justify-start items-center gap-2.5 inline-flex">
        <div className="h-[380px] flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-[47px] text-white text-[24px] md:text-[40px] font-bold font-['Inter']">
            {original.split(/<b>.*?<\/b>/g).map((text, index) => (
              <React.Fragment key={index}>
                <span>{text}</span>
                {bold[index] && (
                  <span className="text-[#db2c47]">{bold[index]}</span>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="self-stretch justify-start items-start gap-[182px] inline-flex">
            <div className="w-full md:w-[673px] flex-col justify-start items-start gap-[17px] inline-flex">
              <div className="self-stretch h-[29px] text-white text-[24px] md:text-[25px] font-bold font-['Inter']">
                {title}
              </div>
              <div className="self-stretch h-[175px] text-white text-base md:text-lg font-normal font-['Open Sans'] leading-relaxed">
                {description}
              </div>
            </div>
            <Image
              className="hidden md:block w-[548px] h-[309px] rounded-[20px]"
              src={image!}
              alt={alt}
              width={548}
              height={309}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <div className="relative w-full md:w-[1400px] h-[364.51px] flex flex-col md:flex-row">
        <div className="relative w-full md:w-[1000px] h-[140px] md:h-[364.51px] overflow-hidden mt-4 md:mt-0">
          {videoUrl && videoUrl.trim() !== '' ? (
            <video
              className="absolute h-full w-full md:max-w-[600px] object-contain md:object-cover 
                         left-1/2 -translate-x-1/2 md:left-[calc(40%+80px)] md:-translate-x-1/2"
              autoPlay
              muted
              loop
              playsInline
              src={videoUrl}
            />
          ) : (
            <div className="relative w-full h-full px-12 md:px-0">
              <div className="relative w-full h-full flex items-center justify-center">
                <Image
                  src={image!}
                  alt={alt}
                  layout="fill"
                  objectFit="contain"
                  priority
                  className="rounded-lg md:rounded-none"
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 md:mt-0 md:ml-4 text-left px-4 md:px-0 md:flex md:flex-col md:justify-center">
          <div className="mb-2 md:mb-8">
            {original.split(/<b>.*?<\/b>/g).map((text, index) => (
              <React.Fragment key={index}>
                <span className="text-white text-[24px] md:text-[40px] font-bold font-['Inter'] block">
                  {text}
                </span>
                {bold[index] && (
                  <span className="text-[#db2c47] text-[24px] md:text-[40px] font-bold font-['Inter'] block">
                    {bold[index]}
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="h-12 md:hidden" />
          <div className="text-white text-[11px] font-normal font-['Open Sans'] leading-[17px] w-[60%] md:w-[207px] pt-[40px] md:pt-0">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerItem;
