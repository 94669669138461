import BannerItem from './BannerItem';
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useHeaderData } from '../../fetcher/useHeaderData';
import { useRouter } from 'next/router';
import { extractBoldText } from '@/utils/htmlParser';

const Banner = () => {
  const router = useRouter();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const autoSlideInterval = 8000; // 8 seconds

  // 현재 경로에 따른 페이지 타입 설정
  const pageType = (() => {
    const fullPath = router.asPath;
    if (fullPath.startsWith('/company')) return 'Company';
    if (fullPath.startsWith('/product')) return 'Products';
    if (fullPath.startsWith('/market')) return 'Market';
    if (fullPath.startsWith('/contact')) return 'Contact';
    return 'Home';
  })();

  const { headerData } = useHeaderData(pageType);

  // 현재 경로에 따른 데이터 선택 로직 수정
  const items = headerData?.headerItems || [];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1,
    );
  };

  useEffect(() => {
    const slideTimer = setInterval(nextSlide, autoSlideInterval);
    const progressTimer = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 100));
    }, autoSlideInterval / 100);

    return () => {
      clearInterval(slideTimer);
      clearInterval(progressTimer);
      setProgress(0);
    };
  }, [currentIndex, autoSlideInterval]);

  // Transform API data to match BannerItem props
  const transformedItems = items.map((item) => ({
    id: item.id,
    image: item.imageLink,
    videoUrl: item.videoUrl,
    alt: item.title,
    title: item.title,
    description: item.content || '',
  }));

  // 배너 아이템이 1개인 경우 다른 레이아웃 사용
  if (items.length === 1) {
    const item = transformedItems[0];
    const { bold, original } = extractBoldText(item.title);

    return (
      <div className="relative w-full h-[500px]">
        {item.videoUrl && item.videoUrl.trim() !== '' ? (
          <video
            src={item.videoUrl}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          />
        ) : (
          <img
            src={item.image || undefined}
            alt={item.alt}
            className="w-full h-full object-cover"
          />
        )}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white text-[24px] md:text-[40px] font-bold text-center max-w-[1200px] px-4 whitespace-pre-line mt-20 font-inter">
            {original.split(/<b>.*?<\/b>/g).map((text, index) => (
              <>
                <span key={`text-${index}`}>{text}</span>
                {bold[index] && (
                  <span key={`bold-${index}`} className="text-[#bc263d]">
                    {bold[index]}
                  </span>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-screen overflow-hidden bg-black">
      <div className="h-[500px] md:h-[589px] w-screen">
        <div
          className="w-full h-full transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          <div className="flex w-full h-full">
            {transformedItems.map((item) => (
              <div key={item.id} className="w-full flex-shrink-0">
                <BannerItem
                  image={item.image || undefined}
                  videoUrl={item.videoUrl || undefined}
                  alt={item.alt}
                  title={item.title}
                  description={item.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {items.length > 1 && (
        <>
          <div className="absolute bottom-[150px] left-0 right-0 flex justify-between px-[15px] md:hidden">
            <button
              onClick={prevSlide}
              className="bg-black/50 text-white p-2 rounded-full border border-white/30"
            >
              <ChevronLeft />
            </button>
            <button
              onClick={nextSlide}
              className="bg-black/50 text-white p-2 rounded-full border border-white/30"
            >
              <ChevronRight />
            </button>
          </div>

          <div className="hidden md:block">
            <button
              onClick={prevSlide}
              className="absolute top-1/2 left-[15px] transform -translate-y-1/2 
                         bg-black/50 text-white p-2 rounded-full border border-white/30
                         xl:left-[calc((100vw-1440px)/2+15px)]"
            >
              <ChevronLeft />
            </button>
            <button
              onClick={nextSlide}
              className="absolute top-1/2 right-[15px] transform -translate-y-1/2 
                         bg-black/50 text-white p-2 rounded-full border border-white/30
                         xl:right-[calc((100vw-1440px)/2+15px)]"
            >
              <ChevronRight />
            </button>
          </div>

          <div className="mt-4 absolute bottom-4 md:bottom-8 left-0 md:left-1/2 transform md:-translate-x-1/2">
            <div className="h-[3px] flex gap-[9px] px-4 md:px-0">
              {items.map((_, index) => (
                <div
                  key={index}
                  className="w-[60px] md:w-[180px] h-[3px] relative cursor-pointer"
                  onClick={() => setCurrentIndex(index)}
                >
                  <div className="w-full h-full absolute bg-neutral-500" />
                  {index === currentIndex && (
                    <div
                      className="h-full absolute bg-[#bc263d] transition-all ease-linear"
                      style={{
                        width: `${progress}%`,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Banner;
