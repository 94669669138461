import useSWR from 'swr';
import { GetHeaderRes } from '../api/header/types';
import { APIClient } from '../api/client';

const fetcher = async (
  key: string,
  title: string,
): Promise<GetHeaderRes | undefined> => {
  const result = await APIClient.Header.getHeader(title);

  return result.data.result;
};

export const useHeaderData = (title: string) => {
  const {
    data: headerData,
    error,
    isLoading,
  } = useSWR<GetHeaderRes | undefined>(
    ['header', title],
    () => fetcher('header', title),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  return {
    headerData,
    isError: !!error,
    isLoading,
  };
};
