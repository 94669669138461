export const extractBoldText = (
  html: string,
): { bold: string[]; original: string } => {
  const boldTexts: string[] = [];
  const regex = /<b>(.*?)<\/b>/g;

  // 모든 <b> 태그 내용 추출
  let match;
  while ((match = regex.exec(html)) !== null) {
    boldTexts.push(match[1]);
  }

  // 원본 HTML 반환
  return {
    bold: boldTexts,
    original: html,
  };
};
